<template>
  <v-slide-group
    :key="String(reset)"
    v-intersect.once="onObserve"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-slide-item
      v-for="(card, i) in filtered"
      :key="i"
      v-slot="{ active, toggle }"
    >
      <div>
        <project-card
          :active="active"
          :height="height"
          :src="require(`@/assets/paydone/screens/screen-${i + 1}.png`)"
          :width="width"
          v-bind="card"
          class="mx-3"
          @click="toggle"
        />
      </div>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
  export default {
    name: 'SectionProjects',

    components: {
      ProjectCard: () => import('@/components/ProjectCard'),
    },

    props: {
      filter: {
        type: Function,
        default: v => !!v,
      },
      height: {
        type: [Number, String],
        default: 125,
      },
      width: {
        type: [Number, String],
        default: 175,
      },
    },

    data: () => ({
      reset: false,
    }),

    computed: {
      filtered () {
        return this.cards.filter(this.filter)
      },
      cards () {
        const proj = []
        for (var i = 0; i < 10; i++) {
          proj.push({ title: this.$t('sections.projects.' + (i + 1) + '.title'), subtitle: this.$t('sections.projects.' + (i + 1) + '.subtitle') })
        }
        return proj
      },
    },

    methods: {
      onObserve (x, y, isVisible) {
        if (this.reset) return

        // Workaround for now
        // Issue lies within the slide-group's
        // logic to re-calculate the container's
        // overflow
        setTimeout(() => {
          this.reset = !this.reset
        }, 100)
      },
    },
  }
</script>
